// Section 8
import React, { FC, useState } from 'react';

const WhyOwlerB: FC = () => {
  const [value, setValue] = useState<string>('');

  const onChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <div className="flex flex-col sm:flex-row px-8 pb-8 sm:py-16 bg-[#1a1a3b] text-white">
      <div className="flex flex-col justify-center sm:w-1/2 p-8 space-y-6">
        <h5 className="text-sm font-semibold uppercase tracking-wide text-gray-400">
          Why Choose Us
        </h5>
        <h1 className="text-3xl sm:text-4xl font-bold leading-tight">
          Owler is the easiest, fastest way to unlock automatic writing & audio
          feedback
        </h1>
        <p className="text-md sm:text-lg text-gray-300">
          Go beyond multiple choice and improve learner outcomes without
          sacrificing efficiency
        </p>
      </div>

      <div className="flex justify-center items-center sm:w-1/2">
        <form className="w-full max-w-sm space-y-1 sm:space-y-4">
          <input
            value={value}
            onChange={onChange}
            type="email"
            placeholder="Email Address"
            className="w-full px-4 py-3 rounded-md bg-[#2b2b53] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
          />
          <a
            href={`https://meetings.hubspot.com/athena-savino?email=${value}`}
            className="block w-full py-3 text-center sm:text-lg font-semibold text-white bg-indigo-500 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-4 focus:ring-purple-300"
          >
            Get Started
          </a>
        </form>
      </div>
    </div>
  );
};

export default WhyOwlerB;
