// Section 5
import React, { FC } from 'react';
import ServiceCard from './Services/ServiceCard';
import {
  BookOpenIcon,
  ScaleIcon,
  ArrowTrendingUpIcon,
} from '@heroicons/react/24/solid';

const cards = [
  {
    img: <BookOpenIcon />,
    title: 'Assessment depth',
    desc: 'Comprehensive assessments that evaluate learners at multiple levels of understanding',
  },
  {
    img: <ScaleIcon />,
    title: 'Consistent, unbiased feedback',
    desc: 'Feedback that remains balanced and fair across all student submissions',
  },
  {
    img: <ArrowTrendingUpIcon />,
    title: 'Operational savings & revenue growth',
    desc: 'Enhance efficiency while driving cost savings and expanding revenue opportunities',
  },
];

const Impact: FC = () => {
  return (
    <>
      <div className="bg-indigo-100 flex flex-col mx-auto mt-24 w-full">
        <p className="ml-5 sm:ml-16 text-xl font-bold text-indigo-500 mt-7 mb-4 sm:my-10">
          HOW WE HELP OUR CLIENTS
        </p>
        <div className="flex flex-col sm:grid sm:gap-8 sm:grid-cols-3 mx-5 sm:mx-24 mb-12">
          {cards.map((card, index) => (
            <ServiceCard
              key={index}
              img={card.img}
              title={card.title}
              desc={card.desc}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Impact;
