import { useQuery } from '@tanstack/react-query';

import { useApi } from '../contexts/ApiProvider';
import { TotalTokenUsage } from '../types';

const initialtValue: TotalTokenUsage = {
  completion_tokens: 0,
  prompt_tokens: 0,
  total_tokens: 0,
};

interface TotalApiTokenCount {
  data?: TotalTokenUsage | null;
  isLoading: boolean;
  error: Error | null;
}

export const useTotalApiTokenCount = (userId: string): TotalApiTokenCount => {
  if (!userId) {
    return { data: null, isLoading: false, error: null };
  }

  const { api } = useApi();

  const fetchTotalApiTokenCount = async (): Promise<TotalTokenUsage> => {
    try {
      const allApiJobs = await api.getAllApiJobs(userId);

      return allApiJobs.reduce((acc, job) => {
        const completion_tokens = job.token_usage?.completion_tokens || 0;
        const prompt_tokens = job.token_usage?.prompt_tokens || 0;
        const total_tokens = job.token_usage?.total_tokens || 0;
        return {
          ...acc,
          completion_tokens: acc.completion_tokens + completion_tokens,
          prompt_tokens: acc.prompt_tokens + prompt_tokens,
          total_tokens: acc.total_tokens + total_tokens,
        };
      }, initialtValue);
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred.';
      console.error('Error fetching total api token count:', errorMessage);
      throw error;
    }
  };

  const { data, isLoading, error } = useQuery<TotalTokenUsage>({
    queryKey: ['totalApiTokenCount'],
    queryFn: fetchTotalApiTokenCount,
  });

  return { data, error, isLoading };
};
