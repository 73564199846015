import React, { FC, useState } from 'react';
import Somalab from '../../images/partnerLanding/Soma_lab_logo.png';
import UPI from '../../images/partnerLanding/UPI_logo.png';
import MrsBaker from '../../images/hero/mrs-baker.png';
import bottom from '../../images/partnerLanding/bottom.svg';
import bottomRight from '../../images/partnerLanding/bottomRight.svg';
import topLeft from '../../images/partnerLanding/topLeft.svg';
import topRight from '../../images/partnerLanding/topRight.svg';

const testimonials = [
  {
    quote:
      'Owler gives immediate feedback to our professionals within our workflow',
    author: 'Soma Lab',
    role: 'Owler Partner',
    img: `${Somalab}`,
  },
  {
    quote:
      'We can now give more written assessments to test comprehension, at lower costs.',
    author: 'UPI Study',
    role: 'Owler Partner',
    img: `${UPI}`,
  },
  {
    quote:
      "I might cry from the relief I feel in using this product. From the bottom of my teacher's heart, thank you for making a product such as this",
    author: 'Mrs. Baker',
    role: 'Middle School English Teacher',
    img: `${MrsBaker}`,
  },
];

const Testimonials: FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="px-6 py-3 sm:py-10 lg:px-8 sm:mb-12 relative">
      <div className="absolute sm:ml-12 top-0 left-6 sm:left-8">
        <p className="text-xl font-bold text-indigo-500">
          WHAT OUR CLIENTS ARE SAYING
        </p>
      </div>

      <div className="relative mx-auto max-w-6xl sm:py-3 mb-12 sm:mt-16 flex flex-col items-center">
        <img
          src={topLeft}
          alt="top-left-shape"
          className="absolute top-12 sm:top-[-44px] left-1 sm:left-[-60px] w-16 sm:w-32 z-0"
        />
        <img
          src={topRight}
          alt="top-right-shape"
          className="absolute top-12 sm:top-[-44px] left-56 sm:left-72 w-24 sm:w-44 z-0"
        />
        <img
          src={bottomRight}
          alt="bottom-right-shape"
          className="absolute bottom-[200px] sm:bottom-[-60px] right-0 sm:left-[370px] w-16 sm:w-32 z-0"
        />
        <img
          src={bottom}
          alt="bottom-shape"
          className="absolute bottom-52 sm:bottom-[-36px] left-28 sm:left-32 w-16 sm:w-32 z-0"
        />

        <div className="relative flex flex-col items-center sm:flex-row sm:items-center sm:justify-between gap-8 z-50">
          <div className="relative flex-shrink-0 z-50">
            <button
              className="absolute left-[-38px] sm:left-[-56px] top-1/2 transform -translate-y-1/2 py-1 px-3 sm:py-2 sm:px-4 bg-white rounded-full text-lg sm:text-2xl shadow-xl hover:scale-110 transition-transform z-10"
              onClick={handlePrev}
              aria-label="Previous"
            >
              ❮
            </button>

            <img
              src={testimonials[currentIndex].img}
              alt={testimonials[currentIndex].author}
              className="bg-white shadow-md w-64 h-52 sm:w-[430px] sm:h-[360px] object-contain"
            />

            <button
              className="absolute right-[-38px] sm:right-[-56px] top-1/2 transform -translate-y-1/2 py-1 px-3 sm:py-2 sm:px-4 bg-white rounded-full text-lg sm:text-2xl shadow-xl hover:scale-110 transition-transform z-10"
              onClick={handleNext}
              aria-label="Next"
            >
              ❯
            </button>
          </div>

          <div className="flex flex-col justify-center items-start text-left sm:w-1/2 mt-6 sm:mt-0">
            <blockquote className="font-bold font-manrope text-lg sm:text-3xl text-black">
              "{testimonials[currentIndex].quote}"
            </blockquote>
            <p className="mt-4 text-black font-semibold">
              {testimonials[currentIndex].author}
            </p>
            <p className="text-gray-500">{testimonials[currentIndex].role}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
