import React, { FC, useState, useEffect } from 'react';
import HeroNav from '../components/hero/HeroNav';
import HeroDisplayB from '../components/businessLanding/HeroDisplayB';
import PartnersBanner from '../components/businessLanding/PartnersBanner/PartnersBanner';
import Services from '../components/businessLanding/Services/Services';
import Process from '../components/businessLanding/Process';
import Impact from '../components/businessLanding/Impact';
import SuitabilityPanel from '../components/businessLanding/Suitability/SuitabilityPanel';
import Testimonials from '../components/businessLanding/Testimonials';
import WhyOwlerB from '../components/businessLanding/WhyOwlerB';

const BusinessView: FC = () => {
  const [viewportHeight, setViewportHeight] = useState<number>(
    window.innerHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col overflow-hidden min-h-screen bg-white">
      <header className="relative inset-x-0 top-0 z-50">
        <HeroNav />
      </header>
      <HeroDisplayB />
      <PartnersBanner />
      <Services />
      <Process />
      <Impact />
      <SuitabilityPanel />
      <Testimonials />
      <WhyOwlerB />
    </div>
  );
};

export default BusinessView;
