import React, { FC } from 'react';

type PartnersProps = {
  img: string;
  alt: string;
};

const PartnerCard: FC<PartnersProps> = ({ img, alt }) => {
  return (
    <div className="flex m-2 md:m-0 justify-center flex-col">
      <img className="m-5" alt={alt} src={img} />
    </div>
  );
};

export default PartnerCard;
