import React, { useEffect, useState } from 'react';
import { Container } from '../../../dependencyInjection/Container';
import { AssignmentReference } from '../AssignmentList/AssignmentReference';
import AssignmentSelect from './AssignmentSelect';
import SubmissionTable from './SubmissionTable';
import { Submission } from '../hooks/useSubmissions';
import SubmissionChart from './SubmissionChart';
import { useSubmissionScores } from '../hooks/useSubmissionScores';

type AssignmentDashboardProps = {
  container: Container;
};

export default function AssignmentDashboard({
  container,
}: AssignmentDashboardProps): JSX.Element {
  const [assignment, setAssignment] = useState<AssignmentReference | null>(
    null
  );
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const submissionStats = useSubmissionScores(
    container.submissionsProvider,
    assignment ?? undefined // useSubmissionScores accepts assignment as undefined, not null
  );

  useEffect(() => {
    async function refreshScores() {
      if (assignment === null) {
        setSubmissions([]);
        return;
      }
      const submissions =
        await container.submissionsProvider.getSubmissions(assignment);
      setSubmissions(submissions ?? []);
    }
    refreshScores();
  }, [assignment, container, setSubmissions]);

  return (
    <div className="flex flex-col gap-4 justify-start">
      <div className="flex" style={{ width: '33%' }}>
        <div className="flex-1">
          <AssignmentSelect
            selectedAssignment={assignment}
            setSelectedAssignment={setAssignment}
          />
        </div>
      </div>
      <div className="flex gap-4">
        <SubmissionTable
          submissions={submissions}
          assignmentId={assignment?.assignment_id}
        />
        <SubmissionChart
          submissions={submissions}
          submissionStats={submissionStats}
        />
      </div>
    </div>
  );
}
