import React, { FC, ReactNode } from 'react';

type ServiceProps = {
  img: ReactNode;
  title: string;
  desc: string;
};

const ServiceCard: FC<ServiceProps> = ({ title, desc, img }) => {
  return (
    <div className="border p-6 m-2 sm:m-0 sm:p-8 rounded-2xl flex flex-col bg-white justify-between content-center">
      <div className="w-8 sm:w-12 text-indigo-500 mb-1 sm:mb-3">{img}</div>
      <h1 className="mb-3 sm:mb-5 tracking-tight font-manrope font-extrabold text-gray-900 text-lg sm:text-2xl">
        {title}
      </h1>
      <p className="text-start text-sm sm:text-base leading-6 sm:leading-8 font-manrope text-gray-600 tracking-tight">
        {desc}
      </p>
    </div>
  );
};

export default ServiceCard;
