import React from 'react';
import { useAssignmentsContext } from '../../../contexts/AssignmentsContextProvider';
import { AssignmentReference } from '../AssignmentList/AssignmentReference';
import { Autocomplete, FormControl, TextField } from '@mui/material';

interface AssignmentSelectProps {
  selectedAssignment: AssignmentReference | null;
  setSelectedAssignment: (assignment: AssignmentReference | null) => void;
}

export default function AssignmentSelect({
  selectedAssignment,
  setSelectedAssignment,
}: AssignmentSelectProps): JSX.Element {
  const { assignments } = useAssignmentsContext();

  const onSelect = (assignmentId: string | null) => {
    if (assignmentId === null) {
      setSelectedAssignment(null);
      return;
    }

    if (assignments && assignments.length > 0) {
      const newSelectedAssignment = assignments.find(
        (assignment) => assignment.assignment_id === assignmentId
      );
      if (!newSelectedAssignment) {
        return;
      }
      setSelectedAssignment(newSelectedAssignment);
    }
  };

  const options =
    assignments?.map((assignment) => ({
      label: assignment.assignment_name,
      value: assignment.assignment_id,
    })) ?? [];

  return (
    <FormControl sx={{ width: '100%', marginTop: '16px', marginBottom: '8px' }}>
      <Autocomplete
        options={options}
        value={
          selectedAssignment
            ? {
                label: selectedAssignment.assignment_name,
                value: selectedAssignment.assignment_id,
              }
            : null
        }
        getOptionLabel={(option) => option.label ?? ''}
        onChange={(_event, value) => {
          onSelect(value?.value ?? null);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Select an assignment" />
        )}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
      />
    </FormControl>
  );
}
