// Section 3
import React, { FC } from 'react';
import ServiceCard from './ServiceCard';
import {
  ChatBubbleLeftEllipsisIcon,
  ArrowsRightLeftIcon,
  ChartBarIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/solid';

const services = [
  {
    img: <ChatBubbleLeftEllipsisIcon />,
    title: 'Automatic feedback',
    desc: 'Assess learner understanding with short, long, or multipart written or audio submissions',
  },
  {
    img: <ArrowsRightLeftIcon />,
    title: 'Seamless integration',
    desc: 'Owler plugs directly into your systems, with less than 5 hours of engineering time',
  },
  {
    img: <ChartBarIcon />,
    title: 'Data tracking',
    desc: 'Owler provides insight into learner outcomes, review times, changes in feedback, and more',
  },
  {
    img: <ClipboardDocumentCheckIcon />,
    title: 'Rubric generator',
    desc: 'Don’t have grading criteria? Owler can generate criteria based on your curriculum',
  },
];

const Services: FC = () => {
  return (
    <>
      <div className="mt-5">
        <div className="bg-indigo-100 rounded-3xl flex flex-col sm:flex-row m-5 sm:my-10 sm:mx-32 ms:py-0 justify-center">
          <div className="flex flex-col m-5 mb-0 sm:m-12 sm:mr-0 sm:w-96">
            <p className="text-lg font-bold text-indigo-500 mb-3 sm:mb-10">
              WHAT WE DO
            </p>
            <h1 className="text-2xl sm:text-6xl font-manrope font-extrabold sm:leading-[68px] tracking-tighter">
              Owler is built to bring you efficiency while empowering your end
              users
            </h1>
          </div>
          <div className="flex flex-col sm:grid sm:gap-3 sm:grid-cols-2 m-3 sm:m-12">
            {services.map((service, index) => (
              <ServiceCard
                key={index}
                img={service.img}
                title={service.title}
                desc={service.desc}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
