import React from 'react';
import { FC } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import Hero from './views/Hero';
import BusinessView from './views/BusinessView';
import Contact from './views/Contact';
import Resources from './views/Resources';
import LoginView from './views/login/LoginView';
import ForgotPasswordView from './views/ForgotPassword/ForgotPasswordView';
import ResetPasswordView from './views/ResetPassword/ResetPasswordView';
import SignupView from './views/signup/SignupView';
import About from './views/About';
import PricingUnauthenticatedView from './views/pricing/PricingUnauthenticatedView';
import PrivacyPolicyUnauthenticatedView from './views/privacyPolicy/PrivacyPolicyUnauthenticatedView';
import TermsOfServiceUnauthenticatedView from './views/termsOfService/TermsOfServiceUnauthenticatedView';
import { SubmissionViewerView } from './views/viewer/submission/SubmissionViewerView';
import { GradingResultProvider } from './components/review/GradingResultContext';

export const UnauthenticatedRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Hero />} />
      <Route path="partner" element={<BusinessView />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="resources" element={<Resources />} />
      <Route path="login" element={<LoginView />} />
      <Route path="forgot-password" element={<ForgotPasswordView />} />
      <Route path="reset-password" element={<ResetPasswordView />} />
      <Route path="signup" element={<SignupView />} />
      {/* <Route path="pricing" element={<PricingUnauthenticatedView />} /> */}
      <Route path="privacy" element={<PrivacyPolicyUnauthenticatedView />} />
      <Route path="terms" element={<TermsOfServiceUnauthenticatedView />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route
        path="export/:assignmentId/:submissionId"
        element={
          <GradingResultProvider>
            <SubmissionViewerView />
          </GradingResultProvider>
        }
      />
    </Routes>
  );
};

export default UnauthenticatedRoutes;
