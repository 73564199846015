import React, { FC } from 'react';
import SuitabilityCard from './SuitabilityCard';
import {
  UsersIcon,
  PencilSquareIcon,
  LightBulbIcon,
} from '@heroicons/react/24/solid';

const sections = [
  {
    icon: <UsersIcon className="w-6 h-6 text-black" />,
    desc: 'Have a large end-user base',
  },
  {
    icon: <PencilSquareIcon className="w-6 h-6 text-black" />,
    desc: 'Have a want or need to assess writing or audio',
  },
  {
    icon: <LightBulbIcon className="w-6 h-6 text-black" />,
    desc: 'Have clients asking for greater review efficiency',
  },
];

const SuitabilityPanel: FC = () => {
  return (
    <div className="w-full flex justify-center my-6 sm:my-12">
      <div className="w-11/12 p-5 mx-2 sm:p-7 border rounded-2xl flex flex-col sm:flex-row">
        <h1 className="text-xl sm:text-3xl mb-3 sm:mb-0 sm:mr-5 font-bold font-manrope text-start self-center">
          Owler is right for your enterprise if you...
        </h1>
        <div className="flex flex-col sm:flex-row sm:justify-center sm:space-x-8">
          {sections.map((section, index) => (
            <SuitabilityCard
              key={index}
              img={section.icon}
              desc={section.desc}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SuitabilityPanel;
