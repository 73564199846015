// Section 4
import React from 'react';
import CurvedLineSVG from '../../images/partnerLanding/CurvedLine.svg';
import DotSVG from '../../images/partnerLanding/Dot.svg';

const StepsSection = () => {
  const steps = [
    {
      title: 'Inputs',
      description:
        'In less than 5 hours, set up Owler’s API which will ingest your prompt, grading criteria, and student written or audio transcripts.',
    },
    {
      title: 'Owler’s feedback engine',
      description:
        'Owler will run this data through our feedback analysis engine and within 45 seconds...',
    },
    {
      title: 'Results',
      description:
        'Immediately, deliver personalized feedback per learner submission within your workflow.',
    },
  ];

  return (
    <div className="relative sm:max-w-5xl sm:mx-24 mt-6 sm:mb-96 sm:pb-32">
      <div className="flex flex-col mb-[-38px] sm:-mb-56 justify-center ml-6 sm:ml-12">
        <p className="text-lg sm:text-xl font-bold text-indigo-500 mb-4 sm:mb-10 text-roboto">
          HOW WE DO IT
        </p>
        <h2 className="text-4xl sm:text-5xl font-bold text-black sm:mb-6 font-manrope tracking-tighter">
          Fast-Track Your <br /> Feedback Process
        </h2>
        <a
          href="https://meetings.hubspot.com/athena-savino"
          className="z-50 font-roboto self-start mt-4 px-12 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-3xl"
        >
          Get Started
        </a>
      </div>

      <div className="sm:mt-16">
        <div className="absolute sm:w-full sm:block hidden">
          <img src={CurvedLineSVG} alt="Curved Line" className="w-full" />
        </div>

        {/* Mobile View: Stack steps in a column */}
        <div className="relative block h-full font-manrope sm:hidden flex flex-col items-center">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col mt-[-144px] mb-0">
              <div className="relative text-[196px] top-44 left-32 z-0 font-roboto font-extrabold text-zinc-200">
                {index + 1}
              </div>
              <h3 className="text-lg font-bold z-10">{step.title}</h3>
              <p className="mt-2 text-base leading-7 z-20 text-gray-600 w-64">
                {step.description}
              </p>
            </div>
          ))}
        </div>

        {/* Desktop View: Use absolute positioning */}
        <div className="relative h-full font-manrope hidden sm:block">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`absolute flex flex-col z-5 sm:items-start items-center ${
                index === 0
                  ? 'top-52 left-32'
                  : index === 1
                    ? 'top-28 left-2/3 transform -translate-x-1/2'
                    : 'top-[-76px] right-[-160px]'
              }`}
            >
              <div className="z-0 relative text-[204px] font-roboto font-extrabold text-zinc-200 mb-[-258px] sm:ml-40 ml-0">
                {index + 1}
              </div>
              <img
                src={DotSVG}
                alt="Dot"
                className="sm:block hidden mt-2 w-16 h-12"
              />
              <h3 className="z-10 mt-4 text-lg font-bold text-center sm:text-left">
                {step.title}
              </h3>
              <p className="z-10 mt-2 text-base leading-7 text-gray-600 sm:w-64 w-full text-center sm:text-left bg-transparent">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepsSection;
