import React from 'react';
import { Submission } from '../hooks/useSubmissions';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useApi } from '../../../contexts/ApiProvider';
import { Link } from 'react-router-dom';

interface SubmissionTableProps {
  submissions: Submission[];
  assignmentId?: string;
}

export default function SubmissionTable({
  submissions,
  assignmentId,
}: SubmissionTableProps): JSX.Element {
  const { api, analyticsApi } = useApi();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        const userId = api.getCurrentUserId();
        analyticsApi.logAssignmentViewCopyShareableLink(userId);
      },
      (err) => {
        console.error('Failed to copy: ', err);
      }
    );
  };

  const generateStudentLink = (submissionId: string, token: string) => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    return `${baseUrl}/export/${assignmentId}/${submissionId}?token=${token}`;
  };

  const generateSubmissionLink = (
    submissionId: string,
    assignmentId: string
  ) => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    return `${baseUrl}/assignments/${assignmentId}?id=${submissionId}`;
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'title',
      headerName: 'Submission',
      width: 200,
      editable: false,
      renderCell: (params) => (
        <Link
          to={generateSubmissionLink(params.row.id ?? '', assignmentId ?? '')}
        >
          {params.row.title}
        </Link>
      ),
    },
    // {
    //   field: 'generatePDF',
    //   headerName: 'Generate PDF',
    //   width: 110,
    //   align: 'center',
    //   editable: false,
    //   sortable: false,
    // },
    {
      field: 'studentLink',
      headerName: 'Student Link',
      type: 'number',
      width: 100,
      align: 'center',
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button
            className="owler-indigo-button"
            onClick={() =>
              copyToClipboard(
                generateStudentLink(params.row.id ?? '', params.row.token ?? '')
              )
            }
          >
            Copy Link
          </button>
        </Box>
      ),
    },
    {
      field: 'grade',
      headerName: 'Grade',
      type: 'number',
      width: 80,
      align: 'right',
      editable: false,
      sortable: true,
      renderCell: (params) => `${params.row.grade}%`,
    },
  ];

  const rows = submissions.map((submission) => ({
    id: submission.submissionId,
    token: submission.token,
    title: submission.fileName,
    generatePDF: '-',
    grade: submission.grade,
  }));

  return (
    <div className="flex flex-col flex-1 owler-li-bubble owler-border-gray-lg">
      <h2 className="text-xl font-bold mb-4">Graded Submissions</h2>
      <Box
        sx={{
          minHeight: '400px',
          // Removing borders between cells
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
        }}
      >
        <DataGrid rows={rows} columns={columns} />
      </Box>
    </div>
  );
}
