// Section 2
import React, { FC } from 'react';
import PartnerCard from './PartnerCard';
import UPI from '../../../images/partnerLanding/UPI_logo.png';
import SomaLab from '../../../images/partnerLanding/Soma_lab_logo.png';
import NYCCharter from '../../../images/partnerLanding/NYCCS-logo.png';
import Schoolio from '../../../images/partnerLanding/schoolio-logo.png';
import Catalyst from '../../../images/partnerLanding/catalyst_logo.png';

const partners = [
  {
    img: `${UPI}`,
    alt: 'University Pathways International',
  },
  {
    img: `${SomaLab}`,
    alt: 'Soma Lab',
  },
  {
    img: `${NYCCharter}`,
    alt: 'New York City Charter School Center',
  },
  {
    img: `${Schoolio}`,
    alt: 'Schoolio',
  },
  {
    img: `${Catalyst}`,
    alt: 'Catalyst',
  },
];

const PartnersBanner: FC = () => {
  return (
    <>
      <div className="border-t border-b">
        <div className="mx-auto max-w-7xl py-10">
          <h3 className="text-xl text-center text-roboto">
            Over 100k+ learners impacted
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-5">
            {partners.map((partner, index) => (
              <PartnerCard key={index} alt={partner.alt} img={partner.img} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersBanner;
