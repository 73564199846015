// Section 1
import React, { FC, useState } from 'react';
import demo from '../../images/hero/demo.mp4';

const HeroDisplayB: FC = () => {
  const [value, setValue] = useState<string>('');

  const onChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <>
      <div className="relative isolate px-6 lg:px-8 z-0 sm:mb-10">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden sm:-top-80"
          aria-hidden="true"
        ></div>
        <div className="relative">
          <div className="sm:my-16 sm:mx-6 flex justify-between">
            <div className="sm:ml-10 sm:mr-10">
              <div className="flex flex-col">
                <p className="text-md text-indigo-600 font-roboto sm:text-[22px] mb-1">
                  Going beyond multiple choice
                </p>
                <h1 className="text-4xl font-bold text-gray-900 sm:text-6xl font-inter">
                  Drive learner success with automated, personalized feedback
                </h1>
              </div>

              <p className="text-sm mt-5 sm:mt-10 font-semibold sm:text-base text-gray-400 font-inter leading-5">
                Owler’s API easily integrates into your existing platform to
                deliver instant, personalized, and actionable feedback on
                learner writing & audio
              </p>

              <div className="mt-5 sm:mt-10 w-full max-w-1/3 min-w-[200px] font-roboto font-bold mb-5 sm:mb-0">
                <div className="relative">
                  <input
                    value={value}
                    onChange={onChange}
                    type="email"
                    id="email_input"
                    className="block w-full bg-white border-none placeholder:text-zinc-400 text-zinc-500 text-sm sm:text-2xl pl-5 pr-16 py-6 sm:pt-12 transition duration-300 ease focus:outline-none focus:ring-0 shadow-xl focus:shadow-2xl"
                    placeholder="your_email@example.com"
                  />
                  <label
                    htmlFor="email_input"
                    className="invisible sm:visible sm:absolute text-xs sm:text-md text-zinc-400 sm:top-5 z-10 pl-5"
                  >
                    Book a demo using email address:
                  </label>
                  <a
                    href={`https://meetings.hubspot.com/athena-savino?email=${value}`}
                    target="_blank"
                    className="rounded-2xl absolute right-5 top-4 sm:top-auto sm:inset-y-5 bg-indigo-600 py-2 sm:py-3 px-4 sm:py-4 sm:px-12 border border-transparent text-center text-xs sm:text-xl text-white transition-all shadow-sm hover:shadow focus:bg-indigo-700 focus:shadow-none active:bg-indigo-700 hover:bg-indigo-700 active:shadow-none"
                    type="button"
                  >
                    Book Demo
                  </a>
                </div>
              </div>
            </div>

            <div className="hidden  sm:block my-7">
              <video
                className="rounded-sm border-4 border-slate-500 shadow-lg shadow-slate-600/50"
                autoPlay
                loop
                muted
              >
                <source src={demo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroDisplayB;
