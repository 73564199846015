import React from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import AssignmentDashboard from '../../components/assignments/AssignmentDashboard/AssignmentDashboard';
import { AssignmentsContextProvider } from '../../contexts/AssignmentsContextProvider';
import { Container } from '../../dependencyInjection/Container';

type AssignmentDashboardViewProps = {
  container: Container;
};

export default function AssignmentDashboardView({
  container,
}: AssignmentDashboardViewProps): JSX.Element {
  return (
    <Dashboard
      currentNavigation="Assignments Dashboard"
      children={
        <div className="overflow-hidden rounded-lg bg-white shadow border border-gray-200">
          <div className="px-4 py-5 sm:p-6">
            <AssignmentsContextProvider container={container}>
              <AssignmentDashboard container={container} />
            </AssignmentsContextProvider>
          </div>
        </div>
      }
    />
  );
}
