import React, { FC, ReactNode } from 'react';

type SuitabilityCardProps = {
  img: ReactNode;
  desc: string;
};

const SuitabilityCard: FC<SuitabilityCardProps> = ({ img, desc }) => {
  return (
    <div className="flex items-center space-x-4 mb-3 sm:mb-0">
      <div className="h-9 sm:w-16 sm:h-12 px-2 flex items-center justify-center bg-zinc-200 rounded-lg">
        {img}
      </div>
      <p className="text-gray-700 text-md font-roboto">{desc}</p>
    </div>
  );
};

export default SuitabilityCard;
