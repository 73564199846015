import { BarChart } from '@mui/x-charts/BarChart';
import React, { useMemo } from 'react';
import { Submission } from '../hooks/useSubmissions';
import { SubmissionScore } from '../hooks/useSubmissionScores';

interface SubmissionChartProps {
  submissions: Submission[];
  submissionStats: SubmissionScore;
}

export default function SubmissionChart({
  submissions,
  submissionStats,
}: SubmissionChartProps): JSX.Element {
  const grades = submissions.map((submission) => submission.grade!);
  const dataBuckets = useMemo(() => groupByTens(grades), [grades]);

  const mean =
    submissionStats.isLoading || submissionStats.mean === 'No Mean'
      ? '-'
      : `${submissionStats.mean}%`;
  const median =
    submissionStats.isLoading || submissionStats.median === 'No Median'
      ? '-'
      : `${submissionStats.median}%`;
  const mode =
    submissionStats.isLoading || submissionStats.mode === 'No Mode'
      ? '-'
      : `${submissionStats.mode}%`;

  const statsSection = (
    <div className="flex flex-col justify-around">
      <div className="text-center">
        <div className="text-sm">Mean</div>
        <div className="font-semibold text-indigo-600">{mean}</div>
      </div>
      <div className="text-center">
        <div className="text-sm">Median</div>
        <div className="font-semibold text-indigo-600">{median}</div>
      </div>
      <div className="text-center">
        <div className="text-sm">Mode</div>
        <div className="font-semibold text-indigo-600">{mode}</div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col flex-1 owler-li-bubble owler-border-gray-lg">
      <h2 className="text-xl font-bold mb-4">Class Performance</h2>
      <div className="flex">
        {statsSection}
        <BarChart
          xAxis={[
            {
              scaleType: 'band',
              data: Object.keys(dataBuckets),
              label: 'Grade (%)',
            },
          ]}
          leftAxis={null}
          series={[{ data: Object.values(dataBuckets), color: '#818cf8' }]}
          height={300}
        />
      </div>
    </div>
  );
}

function groupByTens(data: number[]) {
  const grouped: { [key: number]: number } = {};

  const min = Math.floor(Math.min(...data) / 10) * 10;
  const max = Math.ceil(Math.max(...data) / 10) * 10;

  for (let i = min; i <= max; i += 10) {
    grouped[i] = 0;
  }

  data.forEach((num) => {
    const range = Math.floor(num / 10) * 10;
    grouped[range] += 1;
  });

  return grouped;
}
